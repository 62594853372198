import React, { Component } from 'react';

import { apiV2, baseV2 } from '../axios';

export const AuthContext = React.createContext({
  fetchedUser: false,
  user: null,
  config: null
});

class AuthProvider extends Component {
  state = {
    fetchedUser: false,
    user: null,
    config: null
  };

  componentDidMount = async () => {
    this.fetchUser();
    this.fetchConfig();
  };

  fetchUser = async () => {
    try {
      const { data: user } = await apiV2.get('/auth/user');

      this.setState({ user });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.setState({ fetchedUser: true });
    }
  };

  fetchConfig = async () => {
    try {
      const { data: config } = await apiV2.get('/config');

      this.setState({ config });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  logout = async () => {
    try {
      await baseV2.get('/auth/logout');

      this.setState({ user: null });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          logout: this.logout
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export { AuthContext as default, AuthProvider };
