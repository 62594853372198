import React from 'react';
import { Route } from 'react-router-dom';

import Scans from './scans/Scans';
import View from './econsults/view/View';
import List from './econsults/ListEconsults';
import InitialPage from './initialPage/InitialPage';

const Content = ({ config, user }) => {
  if (!config) return null;

  return (
    <>
      <Route
        exact
        path="/"
        render={(props) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <InitialPage {...props} user={user} config={config} />
        )}
      />
      <Route
        exact
        path="/econsult/:eConsultId"
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props) => <View {...props} user={user} config={config} />}
      />
      <Route
        exact
        path="/econsults"
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props) => <List {...props} user={user} />}
      />
      <Route
        path="/scans/:status?"
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props) => <Scans {...props} user={user} />}
      />
    </>
  );
};

export default Content;
