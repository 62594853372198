import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';
import { format } from 'date-fns';
import { apiV2 } from '../../axios';

const ListImpressions = ({ status }) => {
  const [impressions, setImpressions] = useState([]);

  useEffect(() => {
    const fetchImpressions = async () => {
      const { data } = await apiV2.get('impressions', { params: { status } });

      setImpressions(data);
    };
    fetchImpressions();
  }, [status]);

  return (
    <Box>
      <Grid
        rows={impressions}
        columns={[
          {
            name: 'reference',
            title: 'Reference'
          },
          {
            name: 'laboratory_reference',
            title: 'Reference laboratoire'
          },
          {
            name: 'patient',
            title: 'Patient',
            getCellValue: (impression) =>
              `${impression.patient.user.firstname} ${impression.patient.user.lastname}`
          },
          {
            name: 'sent_at',
            title: 'Envoyé le',
            getCellValue: (impression) =>
              format(
                impression.tracking_timestamps.sent_to_laboratory_at,
                'dd/MM/yyyy'
              )
          }
        ]}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <Table columnExtensions={['code']} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel />
      </Grid>
    </Box>
  );
};

export default ListImpressions;
