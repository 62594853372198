import React from 'react';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { withStyles } from '@material-ui/styles';
import { hasRole } from '../../utils/user.utils';

// eslint-disable-next-line react/jsx-props-no-spreading
const DrawerLink = (props) => <Link {...props} />;

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar
});

const DrawerContent = ({ classes, user }) => {
  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {hasRole(user, 'supplier-econsult-reviewer') && (
        <ListItem button component={DrawerLink} to="/econsults">
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
          <ListItemText primary="EConsults" />
        </ListItem>
      )}
      {hasRole(user, 'supplier-scanner') && (
        <ListItem button component={DrawerLink} to="/scans">
          <ListItemIcon>
            <AccessTimeIcon />
          </ListItemIcon>
          <ListItemText primary="Scans" />
        </ListItem>
      )}

      <Divider />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(DrawerContent);
