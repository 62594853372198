import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import TextField from '../../form/TextField';

import DialogWithButton from '../../DialogWithButton';
import { apiV2 } from '../../../axios';
import Conditions from './Conditions';
import Complexity from './Complexity';
import { getSelectedConditions } from '../../../utils/conditions.utils';
import { getFirstErrorMessage } from '../../../utils/axios';
import SnackbarContext from '../../SnackbarContext';

const Reject = ({ eConsult, onReject }) => {
  const { open } = useContext(SnackbarContext);

  const handleReject = async (values) => {
    try {
      await apiV2.put(`e-consults/${eConsult.id}/review`, {
        ...values,
        conditions: getSelectedConditions(values.conditions),
        decision: false
      });
      await onReject();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      open(getFirstErrorMessage(error), 'error');
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <Form
        onSubmit={handleReject}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogWithButton
              fullWidth
              color="secondary"
              title="Decline case"
              description="You are about to decline this case. This action is irreversible."
              confirmText="Decline"
              onAccept={handleSubmit}
              content={
                <div>
                  <Conditions />
                  <Complexity options={['too-complexe']} />
                  <Field
                    name="reason_for_refusal"
                    component={TextField}
                    type="text"
                    label="Reason for refusal"
                    fullWidth
                  />
                </div>
              }
            >
              Decline
            </DialogWithButton>
          </form>
        )}
      />
    </Grid>
  );
};

export default Reject;
