import React from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import Layout from './components/Layout';

export default () => {
  return (
    <CookiesProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Layout />
      </MuiThemeProvider>
    </CookiesProvider>
  );
};
