import React from 'react';
import ImagePreview from './ImagePreview';
import STLPreview from './STLPreview';

const FilePreview = ({ file, name }) => {
  if (file.mime_type === 'model/stl') {
    return <STLPreview file={file} name={name} />;
  }

  return <ImagePreview src={file.url} alt={name} />;
};

export default FilePreview;
