/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import Loadable from './Loadable';

import FilePreview from './filePreview/FilePreview';

const styles = (theme) => ({
  thumbnailContainer: {
    width: '100%',
    height: 150,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing()
  }
});

class Download extends Component {
  state = {
    fileContent: null
  };

  render() {
    const { file, classes, description, fileName, isFetching, actionButtons } =
      this.props;

    if (!file || Object.keys(file).length === 0) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div className={classes.thumbnailContainer}>
          <Loadable loading={isFetching}>
            <FilePreview file={file} name={description || fileName} />
          </Loadable>
        </div>
        <Tooltip title="Download" placement="bottom">
          <Fab
            size="small"
            color="secondary"
            className={classes.button}
            href={file.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SaveAltIcon />
          </Fab>
        </Tooltip>
        {actionButtons}
      </div>
    );
  }
}

Download.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object,
  fileName: PropTypes.string,
  description: PropTypes.string.isRequired
};

export default withStyles(styles)(Download);
