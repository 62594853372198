import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { apiV2 } from '../../../axios';
import MyTextField from '../../form/TextField';

const Reference = ({ setImpression }) => {
  const onSubmit = async (values) => {
    const { reference } = values;
    try {
      const { data } = await apiV2.get(`impressions/${reference}`);
      setImpression(data);
      return undefined;
    } catch (error) {
      setImpression(null);
      // eslint-disable-next-line no-console
      console.error(error);
      return { reference: 'Reference introuvable' };
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.reference) {
      errors.reference = 'Obligatoire';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, hasValidationErrors }) => (
        <form onSubmit={handleSubmit}>
          <Grid container justify="center" alignItems="center">
            <Grid
              container
              justify="flex-start"
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <Field
                label="Reference Joovence"
                name="reference"
                component={MyTextField}
                type="text"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={10} sm={5} md={3} lg={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={hasValidationErrors}
                fullWidth
              >
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default Reference;
