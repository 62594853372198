import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import {
  SearchState,
  DataTypeProvider,
  PagingState,
  CustomPaging
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui';

import { format, formatDistance, differenceInYears } from 'date-fns';
import { Paper } from '@material-ui/core';
import { STATUS_PENDING, STATUS_APPROVED } from '../../utils/eConsult.utils';
import Loadable from '../Loadable';

import SelectStatus from './SelectStatus';
import { apiV2 } from '../../axios';
import { getDecisionMaker } from '../../utils/decision.utils';
import { hasRole } from '../../utils/user.utils';
import { useDebounce } from '../../hooks/useDebounce';

const codeColumns = ['id'];

const CodeFormatter = ({ row: econsult }) => (
  <Link to={`/econsult/${econsult.id}`}>{econsult.id}</Link>
);

const CodeTypeProvider = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DataTypeProvider formatterComponent={CodeFormatter} {...props} />
);

const ListEconsults = ({ user }) => {
  const [status, setStatus] = useState(STATUS_PENDING);
  const [searchValue, setSearchValue] = useState('');
  const [response, setResponse] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    setCurrentPage(0);
  };

  const fetchEConsults = async () => {
    const pending = status === STATUS_PENDING;
    setIsLoading(true);
    try {
      const decision = pending ? null : status === STATUS_APPROVED;

      const { data } = await apiV2.get('e-consults', {
        params: { id: searchValue, decision, page: currentPage + 1 }
      });
      setResponse(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = useDebounce(fetchEConsults, 400);

  useEffect(() => {
    onSearch();
  }, [searchValue]);

  useEffect(() => {
    fetchEConsults();
  }, [currentPage, status]);

  if (!hasRole(user, 'supplier-econsult-reviewer')) {
    return <Redirect to="/" />;
  }

  return (
    <Paper elevation={2}>
      <SelectStatus value={status} handleChangeFunction={handleChangeStatus} />
      <Grid
        rows={response?.data ?? []}
        columns={[
          {
            name: 'id',
            title: 'ID'
          },
          {
            name: 'age',
            title: 'Age',
            getCellValue: (econsult) =>
              differenceInYears(new Date(), econsult.date_of_birth)
          },
          {
            title: [
              {
                name: 'pending',
                display: 'Uploaded'
              },
              {
                name: 'approved',
                display: 'Approved on'
              },
              {
                name: 'declined',
                display: 'Declined on'
              }
            ].find((s) => s.name === status).display,
            getCellValue: (econsult) => {
              return `${
                !econsult.reviewed_at
                  ? `${formatDistance(econsult.created_at, new Date())} ago`
                  : format(econsult.reviewed_at, 'do MMMM - h:mm a')
              } ${getDecisionMaker(econsult, user.supplier)}`;
            }
          }
        ]}
      >
        <SearchState onValueChange={setSearchValue} defaultValue="" />
        <CodeTypeProvider for={codeColumns} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={25}
        />
        <CustomPaging totalCount={response?.meta.total ?? 0} />
        <Table columnExtensions={['code']} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel
          messages={{
            searchPlaceholder: 'Search by ID'
          }}
        />
        <PagingPanel />
      </Grid>
      {isLoading && <Loadable loading={isLoading} />}
    </Paper>
  );
};

export default ListEconsults;
