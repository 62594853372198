import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loadable from './Loadable';

const AlertDialog = ({
  open,
  onCancel,
  title,
  description,
  onAccept,
  cancelText,
  confirmText,
  content,
  loading
}) => (
  <div>
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={onCancel} color="secondary">
            {cancelText || 'Cancel'}
          </Button>
        )}
        {onAccept && (
          <Button onClick={onAccept} color="primary" autoFocus>
            <Loadable loading={loading} size={20} noMessage>
              {confirmText || 'Confirm'}
            </Loadable>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  </div>
);

export default AlertDialog;
