import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import Comment from './Comment';
import AddComment from './AddComment';

const Comments = ({ eConsult, onAdd }) => {
  return (
    <Box mb={2}>
      <Paper elevation={2}>
        <Box p={2}>
          <Typography variant="subtitle1" color="textSecondary">
            Comments
          </Typography>
          {eConsult.comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}
          {!(eConsult.reviewed_at && eConsult.is_eligible) && (
            <AddComment eConsult={eConsult} onAdd={onAdd} />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Comments;
