import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const styles = (theme) => ({
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    background: 'linear-gradient(to right, #5CAAAE 0%, #AAD9DC 100%)'
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1
  }
});

export default withStyles(styles)(({ logout, classes, handleDrawerToggle }) => (
  <AppBar className={classes.appBar}>
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerToggle}
        className={classes.navIconHide}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
        Joovence Supplier
      </Typography>
      <IconButton
        onClick={logout}
        color="inherit"
        // className={classes.menuButton}
      >
        <ExitIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
));
