import { Box } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import SnackbarContext from '../../SnackbarContext';

import Reference from './Reference';
import ScanUpload from './ScanUpload';

const CreateScan = () => {
  const [impression, setImpression] = useState();
  const snackbar = useContext(SnackbarContext);

  const handleSend = () => {
    snackbar.open('Les fichiers ont bien été envoyés.', 'success');
    setImpression(null);
  };

  const handleCancel = () => {
    setImpression(null);
  };

  return (
    <Box>
      {!impression ? (
        <Reference setImpression={setImpression} />
      ) : (
        <ScanUpload
          impression={impression}
          onSend={handleSend}
          onCancel={handleCancel}
        />
      )}
    </Box>
  );
};

export default CreateScan;
