import client from 'axios';

export default client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/supplier`,
  withCredentials: true
});

export const baseV2 = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`,
  withCredentials: true
});

export const apiV2 = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/supplier`,
  withCredentials: true
});

export const api = client.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

/**
 * Used for S3 request to avoid cors issues
 */
export const axiosWithoutCredentials = client.create();
