import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { getOptionLabel } from '../../../utils/questions.utils';

export const Questions = ({ eConsult, config }) => {
  if (!eConsult.questions) return null;
  return (
    <Box mb={2}>
      <Paper elevation={2}>
        <Box p={2}>
          <Grid container spacing={2}>
            {eConsult.alignment !== null && (
              <Grid item xs={12} sm={6}>
                <Typography color="textSecondary">
                  I want a better alignment
                </Typography>
                <Typography>{eConsult.alignment ? 'True' : 'False'}</Typography>
              </Grid>
            )}
            {eConsult.occlusion !== null && (
              <Grid item xs={12} sm={6}>
                <Typography color="textSecondary">
                  I want a better occlusion
                </Typography>
                <Typography>{eConsult.occlusion ? 'True' : 'False'}</Typography>
              </Grid>
            )}
            {Object.keys(eConsult.questions).map((questionName, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} sm={6} key={index}>
                <Typography color="textSecondary">
                  {getOptionLabel(config, questionName)}
                </Typography>
                <Typography>
                  {eConsult.questions[questionName] === 'yes'
                    ? 'True'
                    : 'False'}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
