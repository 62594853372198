import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  thumbnail: {
    maxWidth: '100%',
    maxHeight: '100%',
    cursor: 'pointer'
  }
});

const ImagePreview = ({ src, alt, classes }) => {
  return (
    <a href={src} target="_blank" rel="noopener noreferrer">
      <img src={src} alt={alt} className={classes.thumbnail} />
    </a>
  );
};

export default withStyles(styles)(ImagePreview);
