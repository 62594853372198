import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from './AuthContext';
import { SnackbarProvider } from './SnackbarContext';

import Authenticated from './Authenticated';

export default () => (
  <Router>
    <AuthProvider>
      <SnackbarProvider>
        <Authenticated />
      </SnackbarProvider>
    </AuthProvider>
  </Router>
);
