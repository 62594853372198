import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import { AuthContext } from '../../AuthContext';
import Checkbox from '../../form/Checkbox';
import { CONDITIONS_MENU } from '../../../utils/conditions.utils';

const Conditions = () => {
  const { config } = useContext(AuthContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <br />
        <Typography color="textSecondary">Conditions</Typography>
      </Grid>
      {config.menus[CONDITIONS_MENU].options.map((condition) => (
        <Grid item xs={6} sm={4}>
          <Field
            name={`conditions.${condition.value}`}
            component={Checkbox}
            type="checkbox"
            label={condition.label}
            fullWidth
            required
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Conditions;
