import React, { useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Form, Field } from 'react-final-form';

import TextField from '../../form/TextField';
import DialogWithButton from '../../DialogWithButton';
import { apiV2 } from '../../../axios';
import Conditions from './Conditions';
import Complexity from './Complexity';
import { getSelectedConditions } from '../../../utils/conditions.utils';
import SnackbarContext from '../../SnackbarContext';
import { getFirstErrorMessage } from '../../../utils/axios';
import { Tags } from '../../Tags/Tags';

const Approve = ({ eConsult, onApprove }) => {
  const { open } = useContext(SnackbarContext);

  const [tags, setTags] = useState([]);

  const handleApprove = async (values) => {
    try {
      await apiV2.put(`e-consults/${eConsult.id}/review`, {
        ...values,
        conditions: getSelectedConditions(values.conditions),
        decision: true,
        tags
      });
      await onApprove();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      open(getFirstErrorMessage(error), 'error');
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <Form
        onSubmit={handleApprove}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogWithButton
              fullWidth
              title="Approve case"
              description="You are about to approve this case. This action is irreversible."
              confirmText="Approve"
              onAccept={handleSubmit}
              content={
                <div>
                  <Conditions />
                  <Complexity options={['simple', 'mild', 'complexe']} />
                  <Tags tags={tags} onChange={setTags} />
                  <Field
                    name="comment"
                    component={TextField}
                    type="text"
                    label="Comment"
                    fullWidth
                  />
                </div>
              }
            >
              Approve
            </DialogWithButton>
          </form>
        )}
      />
    </Grid>
  );
};

export default Approve;
