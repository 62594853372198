import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import DialogWithButton from '../DialogWithButton';
import { apiV2 } from '../../axios';

const angles = [
  { name: 'occlussal_upper', display: 'Occlusal upper' },
  { name: 'occlussal_lower', display: 'Occlusal lower' },
  { name: 'buccal_left', display: 'Buccal left' },
  { name: 'buccal_right', display: 'Buccal right' },
  { name: 'labial_anterior', display: 'Labial anterior' }
];

const RequestNewPhotos = ({ econsult, onRequestPhotos }) => {
  const [anglesToRequest, setAnglesToRequest] = useState({
    occlussal_upper: false,
    occlussal_lower: false,
    buccal_left: false,
    buccal_right: false,
    labial_anterior: false
  });

  const handleSubmit = async () => {
    try {
      await apiV2.put(`e-consults/${econsult.id}`, anglesToRequest);

      await onRequestPhotos();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  if (econsult.requested_new_photos_at) {
    return (
      <Grid item xs={12}>
        <Typography align="center">
          Waiting for patient to upload new photos.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <DialogWithButton
        fullWidth
        color="primary"
        variant="outlined"
        title="Request new photos"
        description="Which photos would you like to request?"
        confirmText="Send request"
        onAccept={handleSubmit}
        content={
          <Grid container spacing={2}>
            {angles.map((angle) => {
              const { [angle.name]: angleValue } = anglesToRequest;
              return (
                <Grid item xs={12} sm={6} key={angle.name}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            setAnglesToRequest((prevAnglesToRequest) => ({
                              ...prevAnglesToRequest,
                              [angle.name]: event.target.checked
                            }));
                          }}
                          checked={angleValue}
                        />
                      }
                      label={<Typography>{angle.display}</Typography>}
                    />
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>
        }
      >
        Request new photos
      </DialogWithButton>
    </Grid>
  );
};

export default RequestNewPhotos;
