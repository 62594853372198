import React from 'react';
import { Button, Grid } from '@material-ui/core';

import { Form, Field } from 'react-final-form';
import TextField from '../../../form/TextField';
import { apiV2 } from '../../../../axios';

const AddComment = ({ eConsult, onAdd }) => {
  const handleSubmit = async (values, form) => {
    try {
      await apiV2.post(`comments`, {
        content: values.comment,
        econsult_uuid: eConsult.uuid
      });
      // reset form
      form.change('comment', undefined);
      form.resetFieldState('comment');
      if (onAdd) {
        await onAdd();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.comment) {
      errors.comment = 'Obligatoire';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit: onSubmit, hasValidationErrors }) => (
        <form onSubmit={onSubmit}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12}>
              <Field
                variant="outlined"
                name="comment"
                label="Comment"
                multiline
                rows={2}
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={hasValidationErrors}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default AddComment;
