const TAG_TYPE_SUCCESS = 'success';
const TAG_TYPE_WARNING = 'warning';
const TAG_TYPE_INFO = 'info';
const TAG_TYPE_DANGER = 'danger';

export function getTagClassNameByType(type, cls) {
  switch (type) {
    case TAG_TYPE_SUCCESS:
      return cls.tagTypeSuccess;
    case TAG_TYPE_WARNING:
      return cls.tagTypeWarning;
    case TAG_TYPE_INFO:
      return cls.tagTypeInfo;
    case TAG_TYPE_DANGER:
      return cls.tagTypeDanger;
    default:
      return '';
  }
}
