import { useCallback, useState } from 'react';
import { api } from '../axios';

const useTranslate = () => {
  const [translation, setTranslation] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const translate = useCallback(async (text) => {
    try {
      setIsTranslating(true);
      const { data: translatedText } = await api.post('/translate', {
        text
      });

      setTranslation(translatedText);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsTranslating(false);
    }
  }, []);

  return { translation, translate, isTranslating };
};

export default useTranslate;
