import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { format } from 'date-fns';
import { CONDITIONS_MENU } from '../../../utils/conditions.utils';
import { getOptionLabel } from '../../../utils/menus.utils';
import { COMPLEXITY_MENU } from '../../../utils/complexity.utils';
import { getDecisionMaker } from '../../../utils/decision.utils';

const Decision = ({ eConsult, user, config }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography
          color={eConsult.is_eligible ? 'primary' : 'error'}
          align="center"
        >
          {`${eConsult.is_eligible ? 'Approved' : 'Declined'} on ${format(
            eConsult.reviewed_at,
            'MMMM do h:mm a'
          )} ${getDecisionMaker(eConsult, user.supplier)}`}
        </Typography>
        {eConsult.reason_for_refusal && (
          <Typography align="center">
            Reason for refusal : {eConsult.reason_for_refusal}
          </Typography>
        )}
      </Grid>
      {!!eConsult.conditions && (
        <Grid item xs={6} sm={4} md={3}>
          <Typography color="textSecondary">Conditions</Typography>
          <Typography component="ul">
            {eConsult.conditions.map((c) => (
              <li key={c}>
                {getOptionLabel(c, config.menus[CONDITIONS_MENU].options)}
              </li>
            ))}
          </Typography>
        </Grid>
      )}
      {!!eConsult.complexity && (
        <Grid item xs={6} sm={3} md={2}>
          <Typography color="textSecondary">Complexity</Typography>
          <Typography>
            {getOptionLabel(
              eConsult.complexity,
              config.menus[COMPLEXITY_MENU].options
            )}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Decision;
