/* eslint-disable react/require-default-props */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CachedIcon from '@material-ui/icons/Cached';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';

import { Fab } from '@material-ui/core';
import FetchFile from './FetchFile';
import { apiV2 } from '../axios';
import Loadable from './Loadable';
import { uploadToS3 } from '../utils/vapor';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    width: '100%'
  },
  content: {
    padding: theme.spacing(2)
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    border: '1px dashed',
    borderColor: grey[300],
    height: 150,
    cursor: 'pointer',
    overflow: 'hidden'
  },
  button: {
    margin: theme.spacing()
  },
  icon: {
    marginLeft: theme.spacing()
  }
});

class Upload extends Component {
  state = {
    file: null,
    isUploading: false,
    uploadedFile: null,
    hasFile: false,
    refreshFetch: false
  };

  handleDrop = (accepted) => {
    const file = accepted[0];
    const { autoUpload } = this.props;

    this.setState({ file }, async () => {
      if (autoUpload) {
        await this.handleSubmit();
      }
    });
  };

  onFetch = (file) => {
    const { onSuccess } = this.props;

    if (file && Object.keys(file).length) {
      this.setState({ hasFile: true, retryMode: false });

      if (onSuccess) {
        onSuccess(file);
      }
    }
  };

  handleSubmit = async () => {
    const {
      receiverId,
      receiverType,
      resourceId,
      resourceType,
      type,
      meta,
      onSuccess
    } = this.props;
    const { file, refreshFetch } = this.state;

    const uploadedFile = await uploadToS3(file);

    const data = {
      file_key: uploadedFile.key,
      receiver_type: receiverType,
      receiver_id: receiverId,
      ...(resourceId && { resource_id: resourceId }),
      ...(resourceType && { resource_type: resourceType }),
      type,
      meta
    };

    this.setState({ isUploading: true });

    try {
      const response = await apiV2.post('files', data);

      this.setState({
        meta: null,
        receiverType: false,
        receiverId: false,
        type: false,
        isUploading: false,
        uploadedFile: response.data,
        hasFile: true,
        retryMode: false,
        refreshFetch: !refreshFetch
      });

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (error) {
      this.setState({ isUploading: false });
    }
  };

  render() {
    const {
      classes,
      description,
      retryable,
      autoUpload,
      onSuccess,
      readOnly,
      ...rest
    } = this.props;
    const { hasFile, retryMode, isUploading, refreshFetch, file } = this.state;

    const retry = (
      <Tooltip title="Remplacer" placement="bottom">
        <Fab
          size="small"
          component="span"
          color="secondary"
          onClick={() => {
            this.setState({
              uploaded: false,
              retryMode: true
            });
          }}
          className={classes.button}
        >
          <CachedIcon />
        </Fab>
      </Tooltip>
    );

    const fetch = (
      <FetchFile
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        hidden={retryMode}
        description={description}
        onSuccess={this.onFetch}
        actionButtons={!readOnly && <>{hasFile && !retryMode && retry}</>}
        refetch={refreshFetch}
        showNotFound={readOnly}
      />
    );

    const drop = (
      <>
        <Dropzone
          className={classes.dropzone}
          onDrop={this.handleDrop}
          multiple={false}
          disabled={isUploading}
        >
          <Loadable loading={isUploading} message="Upload en cours...">
            <CloudUploadIcon className={classes.icon} />
            <Typography color="textSecondary" component="h2">
              Cliquer pour selectionner un fichier...
            </Typography>
            <Typography>{file && file.name}</Typography>
          </Loadable>
        </Dropzone>
        {!autoUpload && (
          <Button
            onClick={this.handleSubmit}
            size="small"
            variant="contained"
            component="span"
            className={classes.button}
            color="secondary"
            disabled={!file}
          >
            Upload
          </Button>
        )}
      </>
    );

    const cancelRetryButton = retryMode && !isUploading && (
      <Button
        onClick={() => {
          this.setState({ retryMode: false });
        }}
        size="small"
        variant="contained"
        component="span"
        className={classes.button}
        color="secondary"
      >
        Annuler <ClearIcon />
      </Button>
    );

    return (
      <Paper className={classes.root} elevation={2}>
        <div className={classes.content}>
          <Typography gutterBottom color="textSecondary">
            {description}
          </Typography>

          {fetch}
          {(retryMode || !hasFile) && !readOnly && drop}
          {retryMode && cancelRetryButton}
        </div>
      </Paper>
    );
  }
}

Upload.propTypes = {
  retryable: PropTypes.bool,
  autoUpload: PropTypes.bool,
  description: PropTypes.string.isRequired,
  receiverId: PropTypes.number.isRequired,
  receiverType: PropTypes.oneOf(['econsult', 'patient']).isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object,
  fileName: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

export default withStyles(styles)(Upload);
