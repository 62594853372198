import React from 'react';
import { Redirect } from 'react-router-dom';
import { hasRole } from '../../utils/user.utils';

const InitialPage = ({ user }) => {
  if (hasRole(user, 'supplier-econsult-reviewer')) {
    return <Redirect to="/econsults" />;
  }
  if (hasRole(user, 'supplier-scanner')) {
    return <Redirect to="/scans" />;
  }

  return null;
};

export default InitialPage;
