import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { AuthContext } from '../../AuthContext';
import Select from '../../form/Select';
import { COMPLEXITY_MENU } from '../../../utils/complexity.utils';

const Complexity = ({ options }) => {
  const { config } = useContext(AuthContext);

  return (
    <Grid container>
      <Field
        name="complexity"
        label="Complexity"
        component={Select}
        formControlProps={{ fullWidth: true }}
        native
      >
        <option aria-label="None" value="" disabled />
        {config.menus[COMPLEXITY_MENU].options
          .filter((option) => options.includes(option.value))
          .map((c) => (
            <option value={c.value}>{c.label}</option>
          ))}
      </Field>
    </Grid>
  );
};

export default Complexity;
