export const CONDITIONS_MENU = 'e-consult-conditions';

export const getSelectedConditions = (conditions) => {
  let selectedConditions = [];

  if (!conditions) return [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(conditions)) {
    if (conditions[key]) {
      selectedConditions = [...selectedConditions, key];
    }
  }

  return selectedConditions;
};
