import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Box, Button, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import Upload from '../../Upload';
import { apiV2 } from '../../../axios';

const ScanUpload = ({ impression, onSend, onCancel }) => {
  const { patient } = impression;
  const type = (patient.booking && patient.booking.type) || 'pre_treatment';

  const onSubmit = async () => {
    await apiV2.put(`/impressions/${impression.id}/set-as-scanned`);

    onSend();
  };

  return (
    <Box mt={4}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} justify="center">
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="h6"
                >{`${patient.reference} - ${patient.user.firstname} ${patient.user.lastname}`}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Upload
                  receiverId={patient.id}
                  receiverType="patient"
                  resourceId={impression.id}
                  resourceType="impression"
                  fileName={`scan-upper-${impression.reference}`}
                  type="treatment_scan"
                  description="Scan dentaire maxillaire"
                  autoUpload
                  retryable
                  onSuccess={() => {
                    form.change('upper', true);
                  }}
                  meta={{
                    angle: 'upper',
                    serie: type
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Upload
                  receiverId={patient.id}
                  receiverType="patient"
                  resourceId={impression.id}
                  resourceType="impression"
                  fileName={`scan-lower-${impression.reference}`}
                  type="treatment_scan"
                  description="Scan dentaire mandibulaire"
                  autoUpload
                  retryable
                  onSuccess={() => {
                    form.change('lower', true);
                  }}
                  meta={{
                    angle: 'lower',
                    serie: type
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Upload
                  receiverId={patient.id}
                  receiverType="patient"
                  resourceId={impression.id}
                  resourceType="impression"
                  fileName={`scan-occlusion-${impression.reference}`}
                  type="treatment_scan"
                  description="Scan dentaire occlusion"
                  autoUpload
                  retryable
                  onSuccess={() => {
                    form.change('occlusion', true);
                  }}
                  meta={{
                    angle: 'occlusion',
                    serie: type
                  }}
                />
              </Grid>
              <Grid>
                <Box display="flex">
                  <Box mr={2}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={onCancel}
                    >
                      Annuler
                    </Button>
                  </Box>
                  <Button color="primary" variant="contained" type="submit">
                    Envoyer
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
};

export default ScanUpload;
