import React, { useContext, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  Select
} from '@material-ui/core';

import { TagsContext } from '../../store/Tags';
import { Tag } from './Tag';

import { getTagClassNameByType } from './helpers';

import { useCustomStyles } from './styles';

export function Tags({ tags, onChange }) {
  const cls = useCustomStyles();

  const allTags = useContext(TagsContext);

  const [open, setOpen] = useState(false);
  const [availableTags, setAvailableTags] = useState(allTags);

  useEffect(() => {
    const newAvailableTags = allTags.filter((tag) => {
      return !tags.some((id) => {
        return id === tag.id;
      });
    });
    setAvailableTags(newAvailableTags);
  }, [tags, allTags]);

  useEffect(() => {
    if (tags.length === allTags.length) {
      setOpen(false);
    }
  }, [tags.length, allTags.length]);

  const handleDelete = (removedItemId) => {
    onChange(tags.filter((id) => id !== removedItemId));
  };

  const handleChange = (event) => {
    const newTags = event.target.value.map((id) => {
      return allTags.map((tag) => tag.id).find((tagId) => tagId === id);
    });
    onChange(newTags);
  };

  const toggle = (event) => {
    if (event.target.id === 'tags' || event.target.id === 'tags-backdrop') {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="tags-label">Tags</InputLabel>
      <Select
        labelId="tags-label"
        id="tags"
        value={tags}
        onChange={handleChange}
        disabled={tags.length === allTags.length}
        open={open}
        onOpen={toggle}
        onClose={toggle}
        multiple
        fullWidth
        input={<Input id="tags-input" label="Select tags" fullWidth />}
        classes={{
          select: cls.select
        }}
        MenuProps={{
          BackdropProps: {
            id: 'tags-backdrop',
            className: cls.backdrop
          }
        }}
        renderValue={(selectedIds) => {
          const selectedTags = allTags.filter(({ id }) =>
            selectedIds.includes(id)
          );

          return selectedTags.map((item) => (
            <Tag
              key={item.id}
              onDelete={() => handleDelete(item.id)}
              data={item}
              className={cls.tag}
            />
          ));
        }}
      >
        {availableTags.map(({ id, type, name }) => (
          <MenuItem
            key={id}
            value={id}
            className={getTagClassNameByType(type, cls)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
