import React from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { hasRole } from '../../utils/user.utils';

import ListImpressions from './ListImpressions';
import CreateScan from './create/CreateScan';

const Scans = ({ match, user }) => {
  if (!hasRole(user, 'supplier-scanner')) {
    return <Redirect to="/" />;
  }

  const { status } = match.params;

  return (
    <Box>
      <Tabs
        value={status || 'scans'}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab component={Link} to="/scans" label="Ajouter" value="scans" />
        <Tab
          component={Link}
          to="/scans/sent-to-laboratory"
          label="Expédiés"
          value="sent-to-laboratory"
        />
        <Tab
          component={Link}
          to="/scans/scanned"
          label="Terminés"
          value="scanned"
        />
      </Tabs>
      <Switch>
        <Route path="/scans/scanned">
          <ListImpressions status="scanned" />
        </Route>
        <Route path="/scans/sent-to-laboratory">
          <ListImpressions status="sent_to_laboratory" />
        </Route>
        <Route path="/scans">
          <CreateScan />
        </Route>
      </Switch>
    </Box>
  );
};

export default Scans;
