import { useEffect, useRef } from 'react';

export const useDebounce = (fn, wait) => {
  const timeout = useRef();

  const cleanup = () => {
    clearTimeout(timeout.current);
  };

  useEffect(() => {
    return cleanup;
  }, []);

  return (...args) => {
    cleanup();
    timeout.current = setTimeout(() => fn(args), wait);
  };
};
