import React, { Fragment } from 'react';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles';
import DrawerContent from './DrawerContent';

const drawerWidth = 240;

const styles = (theme) => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    },
    height: '100%'
  }
});

export default withStyles(styles, { withTheme: true })(
  ({ classes, theme, mobileOpen, handleDrawerToggle, user }) => (
    <>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <DrawerContent user={user} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
            docked: classes.drawerPaper
          }}
        >
          <DrawerContent user={user} />
        </Drawer>
      </Hidden>
    </>
  )
);
