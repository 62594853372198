const CONFIG_NAME = 'e-consult-questions';

export const getOption = (config, value) => {
  return config.menus[CONFIG_NAME].options.find((o) => o.value === value);
};

export const getOptionLabel = (config, value) => {
  const option = getOption(config, value);
  if (!option) return null;
  return option.label;
};
