import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

export default ({
  input: { checked, name, onChange, ...restInput },
  meta,
  label,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl error={showError}>
      <FormControlLabel
        control={
          <Checkbox
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            name={name}
            inputProps={restInput}
            onChange={onChange}
            checked={!!checked}
          />
        }
        label={label && <Typography>{label}</Typography>}
      />
      {showError && (
        <FormHelperText>
          {meta.error || meta.submitError || undefined}
        </FormHelperText>
      )}
    </FormControl>
  );
};
