import React from 'react';

import {
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box
} from '@material-ui/core';
import {
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_DECLINED
} from '../../utils/eConsult.utils';

const SelectStatus = ({ handleChangeFunction, value }) => {
  return (
    <Box p={2}>
      <Grid container justify="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={value}
              onChange={handleChangeFunction}
              input={<Input id="status" />}
            >
              {[STATUS_PENDING, STATUS_APPROVED, STATUS_DECLINED].map(
                (status) => (
                  <MenuItem key={status} value={status}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SelectStatus;
