import React, { useContext, useState, useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { format, differenceInYears } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconButton } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Photos from '../../Photos';

import { apiV2 } from '../../../axios';
import Decision from './Decision';
import Decide from './Decide';
import SnackbarContext from '../../SnackbarContext';
import Comments from './comments/Comments';
import { hasRole } from '../../../utils/user.utils';
import { Questions } from './Questions';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  iconContainer: {
    marginLeft: theme.spacing()
  },
  icon: {
    fontSize: 18
  }
});

const View = ({ classes, match, config, user }) => {
  const { open } = useContext(SnackbarContext);
  const [eConsult, setEConsult] = useState(null);

  const fetchEConsult = async () => {
    try {
      const { data: fetchedEconsult } = await apiV2.get(
        `e-consults/${match.params.eConsultId}`
      );

      setEConsult(fetchedEconsult);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    fetchEConsult();
  }, []);

  if (!eConsult) {
    return null;
  }

  if (!hasRole(user, 'supplier-econsult-reviewer')) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Paper className={classes.root} elevation={2}>
        <Grid container spacing={3}>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Typography color="textSecondary">ID</Typography>
              <CopyToClipboard
                text={window.location.href}
                onCopy={() => {
                  open('EConsult copied in your clipboard.');
                }}
              >
                <Typography>
                  {eConsult.id}
                  <IconButton
                    size="small"
                    color="primary"
                    aria-label="Copy"
                    className={classes.iconContainer}
                  >
                    <FileCopyIcon className={classes.icon} />
                  </IconButton>
                </Typography>
              </CopyToClipboard>
            </Grid>
            <Grid item sm={3}>
              <Typography color="textSecondary">Age</Typography>
              <Typography>
                {differenceInYears(new Date(), eConsult.date_of_birth)}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography color="textSecondary">Uploaded on</Typography>
              <Typography>
                {format(eConsult.created_at, 'do MMMM - h:mm a')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Comments eConsult={eConsult} onAdd={fetchEConsult} />
      <Paper className={classes.root} elevation={2}>
        <Grid container spacing={3}>
          {!eConsult.reviewed_at ? (
            <Decide eConsult={eConsult} onDecide={fetchEConsult} />
          ) : (
            <Decision eConsult={eConsult} user={user} config={config} />
          )}
        </Grid>
      </Paper>
      <Questions eConsult={eConsult} config={config} />
      <Photos econsult={eConsult} />
    </>
  );
};

export default withStyles(styles)(View);
