import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';

import Upload from './Upload';
import { AuthContext } from './AuthContext';

const Photos = ({ econsult }) => {
  const { config } = useContext(AuthContext);
  const angles = config.menus['e-consult-angles'].options;

  return (
    <Grid container spacing={3}>
      {angles.map((angle) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={angle.value}>
          <Upload
            receiverId={econsult.id}
            receiverType="econsult"
            type="treatment_photo"
            meta={{
              angle: angle.value,
              serie: 'pre_assessment'
            }}
            fileName={`photo-${angle.value}-pre_assessment-${econsult.reference}`}
            description={angle.label}
            retryable
            autoUpload
            readOnly
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Photos;
