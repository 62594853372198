import React from 'react';
import Reject from './Reject';
import Approve from './Approve';
import RequestNewPhotos from '../RequestNewPhotos';

const Decide = ({ eConsult, onDecide }) => {
  return (
    <>
      <Reject eConsult={eConsult} onReject={onDecide} />
      <Approve eConsult={eConsult} onApprove={onDecide} />
      <RequestNewPhotos econsult={eConsult} onRequestPhotos={onDecide} />
    </>
  );
};

export default Decide;
