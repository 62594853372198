/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';

import { baseV2 } from '../axios';

import AppBar from './AppBar';
import Drawer from './drawer/Drawer';
import Content from './Content';
import RedirectToAuth from './RedirectToAuth';

import { AuthContext } from './AuthContext';
import { TagsContext } from '../store/Tags';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  }
});

class Authenticated extends Component {
  state = {
    mobileOpen: false,
    tags: []
  };

  componentDidMount() {
    this.fetchTags();
  }

  fetchTags = async () => {
    try {
      const { data: newTags } = await baseV2.get('/tags');
      this.setState({ tags: newTags });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes } = this.props;
    const { mobileOpen, tags } = this.state;
    return (
      <TagsContext.Provider value={tags}>
        <AuthContext.Consumer>
          {({ fetchedUser, user, logout, config }) => {
            if (!fetchedUser) {
              return null;
            }

            if (fetchedUser && !user) {
              return <RedirectToAuth />;
            }

            if (!config) return null;

            return (
              <div className={classes.root}>
                <AppBar
                  handleDrawerToggle={this.handleDrawerToggle}
                  logout={logout}
                />

                <Drawer
                  handleDrawerToggle={this.handleDrawerToggle}
                  mobileOpen={mobileOpen}
                  user={user}
                />

                <main className={classes.content}>
                  <div className={classes.toolbar} />
                  {user && (
                    <Route
                      render={(props) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <Content {...props} user={user} config={config} />
                      )}
                    />
                  )}
                </main>
              </div>
            );
          }}
        </AuthContext.Consumer>
      </TagsContext.Provider>
    );
  }
}

Authenticated.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Authenticated);
