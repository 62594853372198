import { makeStyles } from '@material-ui/core';

import { green, amber, blue, red } from '@material-ui/core/colors';

export const useCustomStyles = makeStyles((theme) => ({
  select: {
    height: 32
  },
  tag: {
    marginRight: theme.spacing(1),

    color: theme.palette.common.white
  },
  backdrop: {
    backgroundColor: 'transparent !important'
  },
  tagTypeSuccess: {
    backgroundColor: green[500]
  },
  tagTypeWarning: {
    backgroundColor: amber[700]
  },
  tagTypeInfo: {
    backgroundColor: blue[500]
  },
  tagTypeDanger: {
    backgroundColor: red[500]
  }
}));
