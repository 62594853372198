import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { STLViewer } from 'react-stl-obj-viewer';
import { axiosWithoutCredentials } from '../../axios';

const STLPreview = ({ file, name }) => {
  const [fileContent, setFileContent] = useState(null);

  useEffect(() => {
    const getFileContent = async () => {
      const { data: content } = await axiosWithoutCredentials.get(file.url, {
        responseType: 'arraybuffer'
      });

      const formattedContent = new File([content], `${name}.stl`, {
        type: file.mime_type
      });

      setFileContent(formattedContent);
    };
    getFileContent();
  }, []);

  if (!fileContent) return null;

  return (
    <Box>
      <STLViewer
        file={fileContent}
        modelColor="#FFFFF"
        backgroundColor="#FAACA8"
      />
    </Box>
  );
};

export default STLPreview;
